


























































import { Vue, Component, Prop } from 'vue-property-decorator'

@Component({})
export default class ExperimentsDialog extends Vue {
  @Prop({ type: Boolean }) value!: boolean

  get isOpen() {
    return this.value
  }

  set isOpen(value: boolean) {
    this.$emit('input', value)
  }
}
